<template>
  <div>
    <!-- Error display -->
    <ErrorsDisplay
      :errors="authenticationErrors"
      class="mx-auto limited-width"
    />

    <!-- Login card -->
    <v-card
      class="mx-auto limited-width"
      elevation="4"
    >
      <!-- Rotec logo, not shown on mobile -->
      <v-img
        contain
        class="rotec-logo d-none d-sm-flex"
        alt="Rotec logo"
        src="@/assets/rotec-logo.png"
      />

      <!-- Login form -->
      <v-form>
        <v-container>
          <!-- Email address field -->
          <v-text-field
            v-model="emailAddress"
            :label="$t('login.email-address')"
            autocomplete="email"
            append-icon="mdi-account"
            hide-details="auto"
            class="mb-6"
            autofocus
            @keyup.enter="tryLogin"
          />

          <!-- Password field -->
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('login.password')"
            autocomplete="off"
            hide-details="auto"
            @click:append="showPassword = !showPassword"
            @keyup.enter="tryLogin"
          />

          <v-btn
            :to="'/forgot-password'"
            plain
            class="float-right text-none mt-1 pe-0"
            small
          >
            {{ $t('login.forgot-password-button') }}
          </v-btn>

          <!-- Login button -->
          <v-btn
            :disabled="!enableLoginButton"
            :loading="isBusyAuthenticating"
            block
            large
            color="primary"
            class="mt-12"
            @click="tryLogin"
          >
            {{ $t('login.login-button') }}
          </v-btn>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ErrorsDisplay from '@/components/ErrorsDisplay'

export default {
  components: { ErrorsDisplay },
  data () {
    return {
      showPassword: false,
      emailAddress: '',
      password: ''
    }
  },
  methods: {
    ...mapActions('account', ['obtainToken']),
    ...mapActions('menuItems', ['updateMenuItemsToRoles']),
    async tryLogin () {
      try {
        await this.obtainToken({ emailAddress: this.emailAddress, password: this.password })
        this.updateMenuItemsToRoles(this.roles)

        // Navigate to home page if the login and retrieval of user groups succeeded.
        this.$router.push({ name: 'Home' })
      } catch (error) {
        // Doing nothing with errors, the account store should fill the 'authenticationErrors'.
      }
    }
  },
  computed: {
    ...mapState('account', ['authenticationErrors', 'isBusyAuthenticating']),
    ...mapGetters('account', ['roles']),
    enableLoginButton () {
      return this.emailAddress.length > 0 && this.password.length > 0
    },
    hasErrors () {
      return this.authenticationErrors && this.authenticationErrors.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.rotec-logo {
  height: 7rem;
}

.limited-width {
  max-width: 400px;
}
</style>
